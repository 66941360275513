.header {
	display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
	position: absolute;
	z-index: 10;
	padding: 20px;

	.lavigna-logo {
		width: 110px;
		height: 63px;
		display: block;
		fill: $white;
	}

	.button {
		font-size: 14px;
		min-width: auto;

		&:before {
			color: $black;
		}
	}

	&__toggle {
	    width: 27px;

		.gracias & { display: none;}	    
	}

	&__toggle-line {
	    display: block;
	    background: $white;
	    width: 100%;
	    height: 2px;
	    margin-bottom: 6px;
	    opacity: 1; 
	    transition: opacity 600ms ease 200ms, top 300ms ease, left 300ms ease, background 300ms ease, transform 300ms ease 450ms;    
		
		&:nth-child(1) {
		    transform-origin: center;
		}
		&:nth-child(3) {
		    transform-origin: center;
		}
	}    

	&.is-active {
	    position: fixed;
	    z-index: 11;

		.header__toggle-line {

			&:nth-child(1) {
			    transform: translateY(8px) rotate(45deg);
			}
			&:nth-child(2) {
			    opacity: 0;
			}
			&:nth-child(3) {
			    transform: translateY(-8px) rotate(-45deg);
			}
		}
	}

	&.fixed {
	    position: fixed;
	    animation: headerFix .5s ease-out;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 10%, rgba(0, 0, 0, 0.7) 30%, transparent);
	}	

	@include breakpoint(tablet) {
		padding: 40px 60px 0;
		justify-content: flex-start;
 
		.lauberge-logo {
			width: 130px;
			height: auto;
		}

		.button {
			display: none;
		}

		&__toggle {
		    display: none;
		}	

		&.fixed {
			position: absolute;
		}

		&.is-active {
		    position: absolute;
		    z-index: 10;
		}
	}
}

section.menu {
	font-family: $font-sans;
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 11;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding-top: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
    background: $black;
    transition: opacity 400ms ease, left 100ms ease 600ms;
	
	&.is-visible {
	    left: 0;
	    opacity: 1;
	    transition: left 100ms ease, opacity 400ms ease 200ms;
	}
	
	.menu-inner {
	    margin: 0;
		text-align: center;
		
		.menu-list {
		    list-style: none; 
		    margin: 0;
		    padding: 0;
		    
			li {
			    font-size: 18px;   
			    margin-bottom: 22px;
			    
				a {
				    color: $white;
					
					&.button {
						display: none;
					}
				}

			}
		}
	}

	.gracias & { display: none;}

	@include breakpoint(tablet) {
		position: absolute;
		left: 0;
		top: 56px;
		z-index: 11;
		opacity: 1;
		background: none;
		padding: 0px 40px;  
		height: auto;
		align-items: flex-end;
		pointer-events: none;
		
		.menu-inner {
			display: flex;

			.menu-list {
			    display: flex;
			    align-items: center;
			    
				li {
				    margin: 0 22px;
					
					a {
					    line-height: 1.6;
					    color: $white;
						border-bottom: 1px transparent solid;
					    padding: 0 0 8px;
					    transition: all 400ms ease;
						pointer-events: all;
					    
					    &:hover {
					    	border-bottom-color: $white;
					    }
					}
				}
			}		
		}

		&.fixed {
			height: 80px;
		    top: 0;
			padding: 13px 20px;
			background:
				url('../img/logo-lavigna-header.svg') no-repeat 20px center,
				linear-gradient(180deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
			background-size: 70px, auto;
			position: fixed;
			animation: headerFix .5s ease-out;
		}
	}
}

.toolbox {
	display: none; 

	@include breakpoint(tablet) {
		display: flex;
		position: fixed;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		flex-direction: column;
		background: rgba($black, .6);
		padding: 26px 16px;
		border-radius: 8px 0 0 8px;
		z-index: 6;

		a {
			margin-bottom: 16px;

			&:last-child {
				margin: 0;
			}
		}

		.icon {
			width: 34px;
			fill: $white;
			transition: all .3s ease;
		}
	}

	@include breakpoint(laptop) {

		a {

			&:hover {
				
				.icon {
					fill: $hover;
				}
			}
		}
	}
}

@keyframes headerFix {
  0%   { top: -150px; }
  100% { top: 0; }
}