video {
	object-fit: cover;
	width: 100%;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	z-index: -1;

	@include breakpoint(tablet) {
		display: block;
	}
}

.hero {
	background-image: 
		linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
		url("../img/hero-m.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center, bottom center;
	height: 100vh;
	position: relative;
	z-index: 1;

	.arrow-down	{
		display: block;
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		
		svg {
			width: 42px;
		}
	}

	.language {
		display: none;

		@include breakpoint(tablet) {
			display: flex;
			z-index: 2;
			position: absolute;
			right: 30px;
			bottom: 30px;

			a {
				font-size: 14px;
				font-family: $font-sans;
				color: $white;
				padding: 6px 8px;
				border: 1px transparent solid;
				margin-right: 8px;

				&.active {
					border-color: $white;
				}

				&:hover {
					background-color: $white;
					color: $black;
				}
			}
		}
	}

	&.lactea {
		background-image: 
		linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
		url("../img/hero-lactea-m.jpg");
	}
	&.posada {
		background-image: 
		linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
		url("../img/hero-posada-m.jpg");

		+ .heading .top {
			margin-bottom: 0;
		}
	}
	&.farm {
		background-image: 
		linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
		url("../img/hero-farm-m.jpg");
	}	

	.gracias & {
        padding: 0 30px;
		display: flex;
		align-items: center;
		background-image: 
		linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.7)), 
		url("../img/hero-m.jpg");

		h2 {
			font-size: 32px;
			font-family: $font-sans;
			font-weight: bold;
			color: $white;
		} 
	}

	@include breakpoint(mobilelandscape) {
		padding-top: 100px;
		background-position: center;
	}
	@include breakpoint(tablet) {
		background-image:
			linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 0.6)), 
			url("../img/hero.jpg");
		align-items: center;

		&.lactea {
			background-image: 
			linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
			url("../img/hero-lactea.jpg");
		}	
		&.posada {
			background-image: 
			linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7));
			// background: none;
		}
		&.farm {
			background-image: 
			linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
			url("../img/hero-farm.jpg");
		}	
		.gracias & {
			padding: 0 60px;
			background-image: 
			linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.7)), 
			url("../img/hero.jpg");
	
			h2 {
				font-size: 42px;
			} 
		}		
	}
	@include breakpoint(laptop) {
		min-height: 90vh;
				
		.gracias & {
			min-height: 100vh;
		}
	}
	@include breakpoint(desktop) {
		background-image: 
		linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 0.6)), 
		url("../img/hero-h.jpg");
		
		&.lactea {
			background-image: 
			linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
			url("../img/hero-lactea-h.jpg");
		}
		// &.posada {
		// 	background-image: 
		// 	linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
		// 	url("../img/hero-posada-h.jpg");
		// }
		&.farm {
			background-image:
			linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7)), 
			url("../img/hero-farm-h.jpg");
		}	
		.gracias & {
			padding: 0 60px;
			background-image: 
			linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5) 30%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.7)), 
			url("../img/hero-h.jpg");
		}
	}
}