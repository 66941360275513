.press {
    padding: 30px;
    background-color: $bg-grey;
    margin-bottom: 48px;

    h2 {
        font-family: $font-sans;
        font-size: 28px;
        padding-top: 16px;
        margin-bottom: 28px;
        position: relative;

        &:before {
            content: "";
            width: 30px;
            height: 1px;
            background: $black;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    .notes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 38px;
    }
    .button {
        width: 180px;
        margin: 0 auto;
    }

    + .item {
        margin: 30px;
        margin-bottom: 48px;
    }

    @include breakpoint(mobilelandscape) {

        .notes {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @include breakpoint(phablet) {

        .notes {
            grid-template-columns: repeat(5, 1fr);
        }
    }
    @include breakpoint(tablet) {
        padding: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .notes {
            grid-template-columns: repeat(6, 1fr);
        }

        + .item {
            margin: 40px 40px 80px auto;
        }        
    }
}