.footer {
    padding: 120px 30px 30px;
    background: $black;
    color: $white;
    font-family: $font-sans;

	.lavigna-logo {
        fill: $white;
		width: 160px;
		height: auto;
		margin: 0 auto 42px;
	}

    h3 {
        font-family: $font-sans;
        font-size: 28px;
        padding-bottom: 16px;
        margin-bottom: 48px;
        position: relative;

        &:after {
            content: "";
            width: 30px;
            height: 1px;
            background: $white;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .items {
        margin-bottom: 48px;

        a {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: $white;
            background: url(../img/footer-map.svg) no-repeat left center;
            background-size: 23px;
            padding-left: 42px;
            margin-bottom: 12px;
            min-height: 44px;

            &.mail {
                background-image: url(../img/footer-mail.svg);
            }
            &.phone {
                background-image: url(../img/footer-phone.svg);
            }
            &:hover {
                color: $hover;
            }
        }
    }

    .gmap {
        margin: 0 -30px 60px -30px;
    }

    .ftoolbox {
        display: flex;
        justify-content: center;
        margin: 70px 0 40px 0;

        a {
            margin: 0 8px;
        }

        .icon {
            fill: $white;
            width: 48px;
        }
    }

    .menu {
        display: flex;
        justify-content: space-between;
        max-width: 360px;
        margin: 0 auto;

        .icon {
            width: 28px;
            height: 28px;
        }
    }

    &_burakko {
        font-family: $font-sans;
        line-height: 48px;
        text-align: center;
        margin-top: 22px;
        
        a {
            color: $black;
        }
    }

	@include breakpoint(tablet) {
        padding: 120px 0 0;
        display: grid;
        grid-template-columns: 30% auto 30%;

        .lavigna-logo {
            width: 220px;
            margin: auto;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
        }

        h3 {
            margin: 40px auto 36px;
            grid-column: 2 / 4;
            grid-row: 1 / 2;
        }

        .items {
            grid-column: 2 / 4;
            grid-row: 2 / 3;
            display: flex;
            justify-content: space-between;
            max-width: 800px;
            margin: 0 auto 58px;

            li {
                flex: 30% 0 0;
            }

            a {
                align-items: flex-start;
                background-position: top left;
    
                &.mail {
                    background-size: 30px;
                    padding-left: 48px;                
                }
                &.phone {
                    background-size: 30px;
                    padding-left: 48px;                
                }
            }
        }

        .gmap {
            grid-column: 1 / 4;
            margin: 0;
        }

        #contactForm {
            grid-column: 2 / 3;
            margin: 60px 0;
        }

        .ftoolbox {
            display: none;
        }
    }
}

