*, *:before, *:after { box-sizing: border-box; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
img {
	display: block;
	width: 100%;
}
h1, h2, h3, h4, h5, h6 {
	line-height: 1.4;
}
ul, li {
	list-style: none;
}
sup {
	font-size: .8em;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, svg {
	display: block;
}
body {
	font: 500 16px $font-serif;
	line-height: 1.2;
	color: $black;
	background-color: $bg-grey;
	position: relative;
}
a {
	text-decoration: none;
	transition: all .3s ease;
} 
p {
	line-height: 1.4;
	margin-bottom: 22px;
	
}
em {
	font-style: italic;
}
strong {
	font-weight: 700;
}
input, select, textarea {
	width: 100%;	
	background: none;
	padding: 12px;
	margin-bottom: 16px;
	border: none;
	border-bottom: 1px $white solid;
	color: $white;
	font-family: $font-sans;
	transition: all .2s ease-in-out;
	
	&::placeholder {
		color: $white;
	}	
	&::-moz-placeholder {
	  opacity: 1;
	}
	&.error {
		border-color: red;
	}
}	
a.button {
	font-weight: 500;
	overflow: hidden;
	padding: 0;
	border: 2px solid;
	border-color: $black;
	background: $black;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	cursor: pointer;
	display: block;
	min-width: 160px;
	text-align: center;

	&:before, & > span {
		padding: .4em .8em;
		transition: transform 0.3s;
		transition-timing-function: ease;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}    
	
	& > span {
	    display: block;
	    vertical-align: middle;
	    color: $white; 
	}

	&:before {
		background: $white;
		color: $black;
		content: attr(data-text);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transform: translate3d(-100%, 0, 0);	    
	}

	&:hover {

		&:before {
		    transform: translate3d(0, 0, 0);
		}
		& > span {
			transform: translate3d(0, 100%, 0);
		}
	}
}
input.button {
	padding: 10px 12px;
    color: $black;
	background: $white;
	border: 2px $white solid;
	transition: all .3s ease-out;
	width: 160px;
	margin: 0 auto;
	display: block;
	
	&:hover {
		background: $black;
		color: $white;
	}
}
.grecaptcha-badge {
	opacity: 0;
}

.jquery-modal {
	z-index: 13;

	h2 {
		font-family: $font-sans;
		font-size: 20px;
		font-weight: bold;
		margin: 16px 0 22px;
	}
	a {
		padding-bottom: 2px;
		border-bottom: 1px $black solid;
		color: $black;

		&:hover {
			border-color: transparent;
		}
	}
    .picture {
        margin: 0 0 26px 0;
    } 
	.modal {
		padding: 20px;
		border-radius: 0;
		max-width: 80%;
	}

	@include breakpoint(tablet) {
		
		h2 {
			font-size: 32px; 
		}
		img {
			width: auto;
			margin: 0 auto 36px;
		}	
		.picture {
			margin: 0;
			max-width: 360px;
		}
		.content {
			display: grid;
			grid-template-columns: 360px auto;
			gap: 30px;
			align-items: center;
		}
	}

	@include breakpoint(laptop) {
		
		.modal {
			max-width: 65%;
			z-index: 14;

			iframe {
				height: 70vh;
			}
		}
	}
}