main {
    padding: 120px 30px 30px;

    @include breakpoint(tablet) {
        padding: 120px 40px 40px;
    }
}

.heading {
    padding: 120px 30px 30px;
    margin-bottom: 6%;
    
    h1 {
        font-size: 32px;
    }    
    h2 {
        font-family: $font-sans;
        font-size: 18px;
        padding-top: 22px;
        margin-bottom: 28px;
        position: relative;

        &:before {
            content: "";
            width: 30px;
            height: 1px;
            background: $black;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    h4 {
        color: $white;
        font-family: $font-sans;
        font-size: 14px;
        padding-top: 16px;
        position: relative;
        transition: color .3s ease;

        &:before {
            content: "";
            width: 30px;
            height: 1px;
            background: $white;
            position: absolute;
            top: 0;
            left: 0;
        }     
    }
    .top {
        margin-bottom: 42px;
    }
    .items {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        a {
            height: 160px;
            padding: 20px;
            display: flex;
            align-items: flex-end;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;

            &[href$='pecorino'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-pecorino.jpg');
            }
            &[href$='ricotta'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-ricotta.jpg');
            }
            &[href$='hatef'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-hatef.jpg');
            }
            &[href$='cabreja'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-cabreja.jpg');
            }
            &[href$='cubic'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-cubic.jpg');
            }
            &[href$='jersey'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-jersey.jpg');
            }
            &[href$='manteca'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-manteca.jpg');
            }
            &[href$='cheddargento'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-cheddargento.jpg');
            }
            &[href$='paysandu'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-paysandu.jpg');
            }
            &[href$='dulceleche'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-dulceleche.jpg');
            }
            &[href$='pipo'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-pipo.jpg');
            }
            &[href$='mucabra'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-mucabra.jpg');
            }
            &[href$='roll'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-roll.jpg');
            }
            &[href$='bauhaus'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-bauhaus.jpg');
            }
            &[href$='bonaparte'] {
                background-image: 
                linear-gradient(to top, rgba(0,0,0,6), rgba(0,0,0,0) ),
                url('../img/cheese-bonaparte.jpg');
            }
        }
    }

    @include breakpoint(phablet) {

        .items {
            grid-template-columns: repeat(4, 1fr);
        }        
    }
    @include breakpoint(tablet) {
        padding: 120px 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        h1 {
            font-size: 38px;
        }
        .top {
            margin-bottom: 68px;
        }
        .items {
            width: 100%;
            grid-template-columns: repeat(5, 1fr);

            a {
                height: 30vh;
                
                &:hover h4 {
                    color: $hover;
                }                   
            }
        }        
    }
}

.reserva {
    color: $white;
    background: url('../img/tambo-m.jpg') no-repeat center bottom;
    background-size: cover;
    padding: 30px;
    min-height: 460px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    h4 {
        font-family: $font-sans;
        font-size: 14px;
        padding-top: 16px;
        margin-bottom: 28px;
        position: relative;
        text-shadow: 4px 4px 5px $black;

        &:before {
            content: "";
            width: 30px;
            height: 1px;
            background: $white;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    h2 {
        font-size: 28px;
        margin-bottom: 22px;
        text-shadow: 4px 4px 5px $black;
    }

    .button {
        width: 180px;
        margin: 0;
        border-color: $white;
        background: $white;
        
        & > span {
            color: $black; 
        }
    
        &:before {
            background: $black;
            color: $white;
        }        
    }

    @include breakpoint(phablet) {
        background: url('../img/tambo.jpg') no-repeat right;
        background-size: cover;
        align-items: flex-end;
        justify-content: center;
        height: 560px;

        .content {
            width: 50%;
        }
    }
    @include breakpoint(tablet) {
        height: 560px;

        .content {
            width: 40%;
        }
    }
    @include breakpoint(desktop) {
        background-image: url('../img/tambo-h.jpg');    
    }
}

.item {
    margin-bottom: 48px;

    h4 {
        font-family: $font-sans;
        font-size: 14px;
        padding-top: 16px;
        margin-bottom: 28px;
        position: relative;

        &:before {
            content: "";
            width: 30px;
            height: 1px;
            background: $black;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    h2 {
        font-size: 28px;
        margin-bottom: 22px;
    }

    p {

        &:last-child {
            margin: 0;
        }
    }

    .button {
        width: 180px;
        margin: 0;
    }

    .planta {
        margin-top: 36px;
    }

    .content {
        margin-bottom: 42px;
    }

    .pictures {

        li {
            display: none;

            &:first-child {
                display: block;
            }
        }
    }

    .dual {

        .picture {
            display: none;
        }
    }

    @include breakpoint(phablet) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .content {
            width: 33%;
            margin: 0;
        }
        .pictures {
            width: 55%;
        }
        &.right {

            .content {
                order: 2;
            }
        }
        &.half {
            align-items: stretch;
            
            .content {
                width: 100%;
                position: relative;
                z-index: 2;
                background: $bg-grey;
                padding-bottom: 20px;
            }
            .dual {
                width: 49%;
                position: relative;
                
                .picture {
                    display: block;
                    width: 70%;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            .pictures {
                width: 49%;
            }
        }
    }
    @include breakpoint(tablet) {
        margin-bottom: 80px;
        margin-right: -40px;

        .content {
            max-width: 350px;
            padding-left: 30px;
        }
        .pictures {
            width: 66%;
            
            li {
                display: block;
            }
        }
        &.right {
            margin-left: -40px;
            margin-right: 0px;
        }
        &.grid {
            align-items: flex-start;
                        
            .pictures {
                display: grid;
                grid-template-columns: repeat(5, 1fr);

                li {
                    &:nth-child(odd) {
                        grid-column: 2 / 6;
                    }
                    &:nth-child(even) {
                        grid-column: 1 / 3;
                        margin: -40px -40px 0 0;
                    }
                }
            }
        }
        &.left {
            
            .content {
                padding: 0 6% 0 0;
            }

            .pictures {
                order: -1;
                
                li {
                    &:nth-child(odd) {
                        grid-column: 1 / 5;
                    }
                    &:nth-child(even) {
                        grid-column: 4 / 6;
                        margin: -40px 0 0 -40px;
                    }
                }
            }
            &.half {

                .pictures {
                    display: flex;
                    justify-content: flex-end;
                }
                .dual {

                    .picture {
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
        &.half {
            overflow: hidden;

            .content {
                max-width: 80%;
                padding: 40px 40px 40px 10%;
            }
            .dual {
                
                .picture {
                    width: 60%;
                }
            }
            .pictures {
                
                img {
                    height: 90vh;
                    width: auto;
                }
            }
        }
    }
    @include breakpoint(laptop) {
        margin-bottom: 120px;
        
        .content {
            max-width: 420px;
            padding-left: 60px;            
        }
        .pictures {
            // width: 66%;
        }
        &.right {

            .content {
                padding: 0 60px 0 0;     
            }
        }        
    }
    @include breakpoint(desktop) {
        
        .content {
            max-width: 480px;
            padding-left: 6%;            
        }
        &.right {

            .content {        
                padding: 0 6% 0 0;
                max-width: 480px;
            }
        }
    }
}